import { Storage } from '@ionic/storage';
import _ from 'lodash';

const APP_PREFERENCES_KEY = "APP_PREFERENCES_STATE";

export interface iAppPreferences {
    userPrefs:{
        accessibility: {
            colourBlindMode: boolean;
        };
    }
    devPrefs:{
        general: {
            buildConfig: {
                production: boolean;
                collectAnalytics: boolean;
                versionNo: string;
                buildNo: string;
            };
            serverEndpoints: {
                production: {
                    domain: string;
                };
                development: {
                    domain: string;
                };
            };
        }
        map: {
            styles: {
                satellite: string;
                street: string;
                colourBlindCompat: string;
            };
            accessTokens: {
                web: string;
            };
        }
    }
}

export class AppPreferences {

    public preferences: iAppPreferences = {
        userPrefs: {
            accessibility: {
                colourBlindMode: false
            }
        },
        devPrefs: {
            general: {
                buildConfig: {
                    production: true,
                    collectAnalytics: false, 
                    versionNo: '2.0.1',
                    // Leave alone, populated by Jenkins
                    buildNo: '#33'
                },
                serverEndpoints: {
                    production: {
                        domain: 'https://ssen-odp.s3.eu-west-1.amazonaws.com',
                    },
                    development: {
                        domain: 'https://ssen-odp-test.s3.eu-west-1.amazonaws.com',
                    }
                },
            },
            map: {
                styles: {
                    satellite: 'mapbox://styles/opengrid/ckyx3bcj7000815ptlikok0xs',
                    street: 'mapbox://styles/opengrid/ckyvm4div003v14qsmjsb5a7s',
                    colourBlindCompat: 'mapbox://styles/opengrid/cl0v7kvlg009114nk5mdt889e'
                },
                accessTokens: {
                    web: 'pk.eyJ1Ijoib3BlbmdyaWQiLCJhIjoiY2t2a3R3eGp4MGoyYzJ4b3V0Y3A5aGU4bCJ9.bSUKW8dOX7NpS4bYG2QLgw',
                },
            }
        }
    };

     // Initialise and return AppPreferences singleton
     private static instance: AppPreferences;
     public static getAppPreferences(): AppPreferences {
         if (!AppPreferences.instance) {
             AppPreferences.instance = new AppPreferences();
         }
         return AppPreferences.instance;
     }
 
     // Populate AppPreferences with locally stored data
     private localDataRestored: boolean = false;
     public async restoreLocallyStoredPreferences() {
         if (!this.localDataRestored) {
             // initialise local storage object
             await this.initialiseStorage();
 
             // Retrieve existing preferences from storage if available
             let filterStateStored = await this.isPrefsStoredLocally()
             if (filterStateStored) {
                 await this.loadPreferences();
                 this.localDataRestored = true;
                 return true;
             } else {
                 return false;
             }
         }
     }
 
     // Initialise local storage object
     private storage!: Storage;
     private async initialiseStorage() {
         this.storage = new Storage();
         await this.storage.create();
     }
 
     // Checks if preferences are currently stored locally
     private async isPrefsStoredLocally() {
         let data = await this.storage.get(APP_PREFERENCES_KEY);
         if (data) {
             return true;
         } else {
             return false;
         }
     }
 
     // Loads locally stored preferences into memory and merges with defaults
     public static loadedLocalPrefs = false;
     private async loadPreferences() {
         _.merge(this.preferences.userPrefs, await this.storage.get(APP_PREFERENCES_KEY));
         AppPreferences.loadedLocalPrefs = true;
     }
 
     // Saves current in memory preferences to local storage
     private async savePreferences() {
         await this.storage.set(APP_PREFERENCES_KEY, this.preferences.userPrefs);
     }

         // Accessibility Preference Setter Methods
    public setColourBlindMode(state: boolean) {
        this.preferences.userPrefs.accessibility!.colourBlindMode = state;
        this.savePreferences();
    }

}